var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "basePage",
      class: {
        "basePage--hidden": _vm.needsAuthentication && !_vm.authenticated
      }
    },
    [
      _c("TopBar"),
      _vm._v(" "),
      _c("a", { staticClass: "noRender", attrs: { id: "afvalpunten" } }),
      _vm._v(" "),
      _c("a", { staticClass: "noRender", attrs: { id: "meldingen" } }),
      _vm._v(" "),
      _c("a", { staticClass: "noRender", attrs: { id: "heatmap" } }),
      _vm._v(" "),
      _c("a", { staticClass: "noRender", attrs: { id: "kaartoverzicht" } }),
      _vm._v(" "),
      _vm.hasHeader
        ? _c(
            "div",
            { staticClass: "basePage_header" },
            [
              _c("Header", {
                key: _vm.headerKey,
                ref: "header",
                attrs: {
                  cfg: _vm.headerCfg,
                  "logo-modifiers": _vm.logoModifiers
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "headerActions",
                      fn: function() {
                        return [_vm._t("headerActions")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              _c("GlobalMessageBox"),
              _vm._v(" "),
              _vm._t("header")
            ],
            2
          )
        : _c("div", [_c("GlobalMessageBox")], 1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "heightWrapper", style: [_vm.heightWrapperStyle] },
        [
          _c(
            "div",
            {
              staticClass: "mainContent",
              class: { "mainContent--overflow": _vm.overflowContent }
            },
            [
              _vm._t("beforeInner"),
              _vm._v(" "),
              !_vm.$slots.withoutWrapper
                ? _c(
                    "div",
                    { staticClass: "mainContent-inner" },
                    [_vm._t("default")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("withoutWrapper")
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _vm.hasFooter
        ? _c("Footer", { ref: "footer", attrs: { large: _vm.largeFooter } })
        : _vm._e(),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "basicOverlays" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "fractionEvolutions" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "measureEdit" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "measureApply" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "projectDuplicate" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "projectClose" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "scanEdit" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "projectComparison" } }),
      _vm._v(" "),
      !_vm.session.gdprUser.acceptProTermsOfUse && _vm.authenticated
        ? _c(
            "span",
            [
              _c("GdprTermsOverlay", {
                ref: "termsOverlay",
                attrs: { gdprUser: _vm.session.gdprUser },
                on: {
                  denyTerms: _vm.showDenyTerms,
                  approveGdpr: _vm.approveGdpr
                }
              }),
              _vm._v(" "),
              _c("GdprTermsDeniedOverlay", {
                ref: "denyOverlay",
                on: { denyTerms: _vm.onDenyTerms, showTerms: _vm.onShowTerms }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("BrowserNotice", { ref: "browserNotice" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }