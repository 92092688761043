// imports
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// general
import Config from '../Config';

// modules
import globalMessage from './modules/globalMessage';
import session from './modules/session';
import bins from './modules/bins';
import community from './modules/community';
import incidents from './modules/incidents';
import volunteers from './modules/volunteers';
import filters from './modules/filters';
import monitoring from './modules/monitoring';
import reporting from './modules/reporting';
import experienceScan from './modules/experienceScan';
import dataModule from './modules/dataModule';
import projectComparison from './modules/projectComparison';
import filterModelsPlugin from './plugins/filterModelsPlugin';

import { getRelativeTimeStampFromNow } from '../utils/dateFormat';

// Apply vuex to vue
Vue.use(Vuex);
// class definition
const store = new Vuex.Store({
	modules: {
		globalMessage,
		session,
		community,
		bins,
		incidents,
		volunteers,
		filters,
		monitoring,
		reporting,
		experienceScan,
		projectComparison,
		projectsDM: dataModule,
		projectsReportDM: dataModule,
		projectMeasurementsDM: dataModule,
		candidateMeasuresDM: dataModule,
		projectBinsDM: dataModule,
		projectsMapDM: dataModule,
		binsDM: dataModule,
		activitiesDM: dataModule,
		activitiesListDM: dataModule,
		groupsDM: dataModule,
		userGroupsDM: dataModule,
		groupMembersDM: dataModule,
		groupPostsDM: dataModule,
		groupAdminsDM: dataModule,
		eventsDM: dataModule,
		eventParticipantsDM: dataModule,
		groupEventsDM: dataModule,
		eventActivitiesDM: dataModule,
		eventHeatmapActivitiesDM: dataModule,
		volunteersDM: dataModule,
		binMeasurementsDM: dataModule,
	},

	plugins: [
		// keep specific vuex store values in local storage
		createPersistedState({
			paths: [
				'session.dateFilters', 
				'session.rememberLoggedIn', 
				'filters.pages', 
				'volunteers.gdprApproved', 
				'showBrowserNotice', 
				'experienceScan.experienceScanDraft',
				'experienceScan.publishingIssue',
				'projectComparison.selectedComparison'
			],
		}),
		filterModelsPlugin([
			'projectsDM',
			'projectsReportDM',
			'projectMeasurementsDM',
			'candidateMeasuresDM',
			'projectBinsDM',
			'projectsMapDM',
			'binsDM',
			'activitiesDM',
			'activitiesListDM',
			'groupsDM',
			'userGroupsDM',
			'groupMembersDM',
			'groupPostsDM',
			'groupAdminsDM',
			'eventsDM',
			'eventParticipantsDM',
			'groupEventsDM',
			'eventActivitiesDM',
			'eventHeatmapActivitiesDM',
			'volunteersDM',
			'binMeasurementsDM',
		])
	],

	state: {
		// general flags
		isMobile: false,
		isTablet: false,
		isTouch: false,

		// flag for auth check
		errorForLogin: '',
		targetPageAfterLogin: Config.routes.home,

		newGarbageBin: null,
		newGarbageBinMeasurement: null,
		newProject: null,

		//flags for chrome browser notice
		showBrowserNotice: true,
		browserNoticeShown: false,

		// exportmethod, added by pages when they provide export functionalities
		// header will pick it up and show/hide links accordingly
		dataExporter: null,

		// page
		pageLoader: null,

		//appwide analysing date
		analyseDate: [getRelativeTimeStampFromNow(-90, false), getRelativeTimeStampFromNow()],
		analyseDateChanged: false
	},

	getters: {
		isMobile: ({ isMobile }) => isMobile,
		isTablet: ({ isTablet }) => isTablet,
		isTouch: ({ isTouch }) => isTouch,
		analyseDate: ({ analyseDate }) => analyseDate,
		analyseDateChanged: ({ analyseDateChanged }) => analyseDateChanged,
	},

	mutations: {
		RESET_ERRORFORLOGIN(state) {
			state.errorForLogin = '';
		},

		SET_DATA_EXPORTER(state, method) {
			state.dataExporter = method;
			window.exporter = method;
		},

		UNSET_DATA_EXPORTER(state) {
			state.dataExporter = null;
			window.exporter = null;
		},

		SHOW_PAGE_LOADER(state) {
			if (state.pageLoader) state.pageLoader.show();
		},

		HIDE_PAGE_LOADER(state) {
			if (state.pageLoader) state.pageLoader.hide();
		},

		screenTouched(state) {
			state.isTouch = true;
		},

		hideBrowserNotice(state) {
			state.showBrowserNotice = false;
		},

		browserNoticeHidden(state) {
			state.browserNoticeShown = true;
		},

		updateAnalyseDate(state, val) {
			state.analyseDate = null;
			state.analyseDate = val;
			state.analyseDateChanged = true;
		}
	},

	actions: {
		updateAuthInfo({ commit, state, getters }) {
			const payload = {
				session: state.session.model,
				user: state.session.user,
				jwt_authorization: getters['session/jwt_authorization'],
			};

			// update sub modules & stores that require auth
			commit('incidents/UPDATE_AUTH', payload);
			commit('bins/UPDATE_AUTH', payload);
			commit('monitoring/UPDATE_AUTH', payload);
			commit('reporting/UPDATE_AUTH', payload);
			commit('volunteers/UPDATE_AUTH', payload);
		},

		onLogout({ state, commit }) {
			state.targetPageAfterLogin = Config.routes.home;
			// update sub modules & stores that require auth
			commit('incidents/ON_LOGOUT');
			commit('bins/ON_LOGOUT');
			commit('monitoring/ON_LOGOUT');
			commit('reporting/ON_LOGOUT')
			commit('activitiesDM/clearFilters')
			commit('activitiesDM/unsetCollection')
			commit('projectComparison/RESET_COMPARISON')
		},
	},
});

// subsciptions
store.subscribe((mutation, state) => {
	switch (mutation.type) {
		case 'session/LOADING':
			if (!state.session.loading) store.dispatch('updateAuthInfo');
			break;
		case 'session/LOGOUT':
			store.dispatch('onLogout');
			break;
	}
});

//  export
window.vueStore = store;
export default store;
