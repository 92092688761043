var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EditOverlay",
    {
      ref: "overlay",
      staticClass: "overlay--editDate",
      attrs: {
        title: _vm.overlayTitle,
        submitName: _vm.submitName,
        root: true
      },
      on: {
        apply: _vm._onApplyClick,
        close: _vm._onCloseClick,
        afterhide: _vm.afterhide
      }
    },
    [
      _c(
        "div",
        { staticClass: "datePicker datePicker-measurement" },
        [
          _c(
            "div",
            { staticClass: "datepicker-container inline-with-input" },
            [
              _c("input", {
                attrs: {
                  id: "datepicker-inline-trigger",
                  type: "text",
                  placeholder: "Select date"
                },
                domProps: { value: _vm.currentValue }
              }),
              _vm._v(" "),
              _c("airbnb-style-datepicker", {
                attrs: {
                  "trigger-element-id": "datepicker-inline-trigger",
                  mode: "single",
                  inline: true,
                  "fullscreen-mobile": false,
                  "show-action-buttons": true,
                  "date-one": _vm.currentValue,
                  "months-to-show": 1,
                  "enabled-dates": _vm.enabledDates,
                  minDate: _vm.minDate
                },
                on: {
                  "date-one-selected": function(val) {
                    return (_vm.currentValue = val)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.infoBox
            ? _c(
                "InfoBox",
                {
                  class: _vm.infoBox.class,
                  attrs: {
                    type: _vm.infoBox.type,
                    withIcon: _vm.infoBox.withIcon
                  }
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.infoBox.message) + "\n\t\t")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }